import {createRouter, createWebHistory, Router} from 'vue-router';

const router: Router = createRouter(
  {
    history: createWebHistory(),
    routes: [
      {
        path: '',
        name: 'mainPage',
        //@ts-ignore
        component: () => import('@/components/MainLayout.vue'),
        beforeEnter: (to, from, next) => {
          if (process.env.VUE_APP_DEMO_MODE) {
            next();
            return;
          }
          if (!localStorage.token) {
            next('/login');
          } else {
            next();
          }
        },
      },

      {
        path: '/login',
        name: 'login',
        //@ts-ignore
        component: () => import('@/components/LoginPage.vue'),
        beforeEnter: (to, from, next) => {
          if (localStorage.token) {
            next('/');
          } else {
            next();
          }
        },
      },

      {
        path: '/callback',
        name: 'callback',
        //@ts-ignore
        component: () => import('@/components/AuthenticationCallback.vue'),
      },

      {
        path: '/:catchAll(.*)',
        redirect: {name: 'mainPage'},
      },
    ],
  },
);

export default router;
