import { createI18n } from 'vue-i18n';
import en from '@/translations/en';
import ua from '@/translations/ua';
import {LocaleEnum} from '@/enums/LocaleEnum';
import {useUserStore} from "@/store/modules/user";
import {createPinia} from "pinia";

const pinia = createPinia();
const userStore = useUserStore(pinia);

const messages = {
  [LocaleEnum.En]: en,
  [LocaleEnum.Ua]: ua,
};

const i18n = createI18n({
  locale: userStore.locale,
  messages,
  allowComposition: true,
  fallbackLocale: LocaleEnum.En,
});

export default i18n;
