export default {
  languageName: 'english',
  logout: 'logout',
  logoutGoogleAccount: 'logout from google account',
  signInWithGoogle: 'Sign in with Google',
  confRoomMenu: {
    book: 'book',
  },
  search: {
    placeholder: {
      full: 'Search by Name, Technologies, Skills',
      small: 'Search',
    },
    sort: {
      label: 'Sort by:',
      type: {
        'floor': 'floor',
        'experience': 'experience',
        'nearest birthday': 'nearest birthday',
      },
    },
    noMatches: 'No matches found.',
  },
  language: 'language | languages',
  floor: 'floor | floors',
  freeSeats: 'free seats',
  remote: 'remote',
  moveTo: 'move to',
  left: 'left',
  right: 'right',
  people: 'people',
  skill: 'skill | skills',
  technology: 'technology | technologies',
  welcomeTo: 'Welcome to',
  loginWith: 'Login with',
  poweredBy: 'Powered by',
  conferenceRoom: 'room | rooms',
  standingTables: 'tables',
  place: 'place | places',
  vacation: 'vacation',
  inOffice: 'in office',
  copy: 'copy',
  unknown: 'unknown',
  birthday: 'birthday',
  workStart: 'work start',
  myProfile: 'my profile',
  till: 'till',
  timeList: 'time list',
  permissionGapiError: 'You do not have permission to access this calendar.',
  generalGapiError: 'Internal server error. Try again later or contact us.',
};
