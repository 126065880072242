import Notifications from '@kyvg/vue3-notification';
// @ts-ignore
import App from './App.vue';
import './assets/scss/style.scss';
import './registerServiceWorker';
import router from './router';
import VueScrollTo from 'vue-scrollto';
import VueGtag from 'vue-gtag';
import i18n from '@/translations/i18n';
// @ts-ignore
import {createPinia} from "pinia";
import "broadcastchannel-polyfill";
import { createApp } from 'vue';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { PiniaSharedState } from 'pinia-shared-state';
import mitt from "mitt";

const pinia = createPinia();
const emitter = mitt();

pinia.use(piniaPluginPersistedstate);
pinia.use(
    PiniaSharedState({
      initialize: true,
      type: 'localstorage',
    }),
);

const app = createApp(App);

app.provide('emitter', emitter);

app.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }, pageTrackerScreenviewEnabled: true,
});
app.use(Notifications);
app.use(VueScrollTo);

app
    .use(pinia)
    .use(router)
    .use(i18n)
    .mount("#app");
